<header>
  <h2><span>Skills</span></h2>
</header>
<article class="skills">
<!--    <div class="skill-item" *ngFor="let skill of skills">-->
<!--      <div class="skill-item__logo">-->
<!--        <img [src]="skill.icon" [alt]="skill.name">-->
<!--      </div>-->
<!--      <div class="skill-item__name">{{skill.name}}</div>-->
<!--      <div class="skill-item__spacer"></div>-->
<!--      <div class="skill-item__level">-->
<!--            <span-->
<!--              *ngFor="let l of [1,2,3,4,5]"-->
<!--              [ngClass]="{'skill-item__level-bubble&#45;&#45;active': l <= skill.level, 'skill-item__level-bubble': true}"-->
<!--            ></span>-->
<!--      </div>-->
<!--    </div>-->

  <div class="skill-item" *ngFor="let skill of skills">
    <div class="skill-item__logo">
      <img [src]="skill.icon" [alt]="skill.name">
    </div>
    <div class="skill-item__info">
      <div class="skill-item__info__name">{{skill.name}}</div>
      <div class="skill-item__info__level">
        <span
          *ngFor="let l of [1,2,3,4,5]"
          [ngClass]="{'skill-item__info__level__bubble--active': l <= skill.level, 'skill-item__info__level__bubble': true}"
        ></span>
      </div>
    </div>
  </div>
</article>
