import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.scss']
})
export class SkillsComponent {
  skills = [
    {
      icon: '/assets/skills/angular.png',
      name: 'Angular',
      level: 5,
    },
    {
      icon: '/assets/skills/typescript.png',
      name: 'TypeScript',
      level: 4,
    },
    {
      icon: '/assets/skills/html.png',
      name: 'HTML',
      level: 5,
    },
    {
      icon: '/assets/skills/css.png',
      name: 'CSS',
      level: 5,
    },
    {
      icon: '/assets/skills/firebase.png',
      name: 'Firebase',
      level: 4,
    },
    {
      icon: '/assets/skills/ionic.png',
      name: 'IONIC',
      level: 4,
    },
    {
      icon: '/assets/skills/nestjs.png',
      name: 'NestJS',
      level: 3,
    },
    {
      icon: '/assets/skills/pwa.png',
      name: 'PWA',
      level: 3,
    }
  ];
}
