
<div class="profile">
  <img src="/assets/face.png" alt="Eduard Stankovic">
</div>
<span class="name">Eduard Stankovič</span>
<div class="contact-info">
  <div class="contact-info-item">
    <span class="label">Location</span>
    <span class="value">Brno, Czech Republic</span>
  </div>
  <div class="contact-info-item">
    <span class="label">Email</span>
    <span class="value">eduard.stankovic@gmail.com</span>
  </div>
  <div class="contact-info-item">
    <span class="label">Phone</span>
    <span class="value">+420 775 478 528</span>
  </div>
  <div class="contact-info-item">
    <span class="label">Linkedin</span>
    <span class="value"><a href="https://www.linkedin.com/in/eduard-stankovič-9449649b/" target="_blank">linkedin.com/in/eduard-stankovič-9449649b</a></span>
  </div>
  <div class="contact-info-item">
    <span class="label">Github</span>
    <span class="value"><a href="https://github.com/estankovic">github.com/estankovic</a></span>
  </div>
  <div class="contact-info-item">
    <span class="label">Website</span>
    <span class="value"><a href="https://stankovic.dev">stankovic.dev</a></span>
  </div>
</div>
<div class="spacer"></div>
<div class="qr-code">
  <img src="/assets/qrcode.svg" alt="">
</div>
