<header>
  <h2><span>Experience</span></h2>
</header>
<article>

  <p>During my career I had chance to work on many different projects.
    From small personal project to big corporate applications that had to serve thousands of customers.</p>
  <p>As all projects were unique, I encountered many different challenges, but I was always able to find a solution or negotiate good compromise to satisfy all interested parties.</p>
  <p>In the list below are <strong>some</strong> of my work experiences or projects that I had privilege to be a part of.</p>

  <div class="experience">
    <div class="experience__info">
      <h3 class="experience__info__title">SentinelOne</h3>
      <div class="experience__info__time">MAY 2022 - Present</div>
      <p class="experience__info__description">
        As a Staff Software Engineer at SentinelOne I help deliver all internal tools, components to our internal feature teams so
        they can focus on solving business needs. Other than that, I assist in migrating Angular Project to React which allowed me to gain new perspectives in Frontend world.
      </p>
    </div>
  </div>

  <div class="experience">
    <!--    <div class="experience__image">-->
    <!--      <img src="https://picsum.photos/200/160?1" alt="">-->
    <!--    </div>-->
    <div class="experience__info">
      <h3 class="experience__info__title">Uniqa.at</h3>
      <div class="experience__info__time">OCT 2020 - MAR 2022</div>
      <p class="experience__info__description">
        At Uniqa.at I had a chance to work on their customer portal MyUniqa. Later I have been transferred to work on transforming theirs internal applications.
        I work in a frontend team consisting of 3 FE developer, where I am the lead developer. Our goal is to introduce new sustainable technology and improve UX and UI.
      </p>
    </div>
  </div>

  <div class="experience">
    <!--    <div class="experience__image">-->
    <!--      <img src="https://picsum.photos/200/160?1" alt="">-->
    <!--    </div>-->
    <div class="experience__info">
      <h3 class="experience__info__title">Revolt.city</h3>
      <div class="experience__info__time">DEC 2020 - JUL 2021</div>
      <p class="experience__info__description">
        For the Revolt.city I had a chance to develop mobile application for theirs servicemen. It was build using the latest trends in Hybrid development such
        as <strong>Angular</strong>, <strong>Ionic 5</strong> and <strong>Capacitor</strong>.
      </p>
      <p>Usage of those technologies allowed fast delivery of the application and reduced the cost of development and maintenance compared to the native counterparts.</p>
    </div>
  </div>

  <div class="experience">
<!--    <div class="experience__image">-->
<!--      <img src="https://picsum.photos/200/160?1" alt="">-->
<!--    </div>-->
    <div class="experience__info">
      <h3 class="experience__info__title">USU Software s.r.o.</h3>
      <div class="experience__info__time">JUL 2017 - JUL 2020</div>
      <p class="experience__info__description">
        USU is a big corporation, that develops many different products/solutions. I had a chance to work on BI tool that helped improve ETL processes build
        with <strong>Angular</strong> using <strong>Angular Material components</strong>.
      </p>
      <p>Later, when the company was consolidating their different sister companies under one brand, I initiated movement to introduce <strong>WebComponents</strong>
        to unify the UI easily as the company used many different technologies for each of theirs products.
        I became lead developer for the Design System. We chose <strong>Stencil.Js</strong> as the technology for building those <strong>WebComponents</strong> and it was a joy to work with it.</p>
    </div>
  </div>

  <div class="experience">
<!--    <div class="experience__image">-->
<!--      <img src="https://picsum.photos/200/160?2" alt="">-->
<!--    </div>-->
    <div class="experience__info">
      <h3 class="experience__info__title">FlowUp s.r.o.</h3>
      <div class="experience__info__time">JUL 2019 - JUN 2020</div>
      <p class="experience__info__description">
        FlowUp as a company focused on developing Cloud Based applications (Google Partner).
        They embraced development communities and organised regular <strong>public meetups</strong> mostly focused on <strong>Angular</strong>
        and I am happy that I had a chance to be part of that as well. I had a chance to attend 2 <strong>Firebase conferences</strong>, one in Prague, the other in Madrid.
      </p>
      <p>
        The project I worked on there was UK based fin-tech startup <a href="https://dashly.com" target="_blank">Dashly<span class="print-only"> (http://dashly.com)</span></a>,
        that aims to give people better understanding over their current mortgage or helps to find better deal for them.
        I was mostly responsible for <strong>removing technological debt</strong> as well as developing new features.
        I gained there a lot of practical knowledge especially of <strong>Firebase</strong> related tools.
      </p>
    </div>
  </div>

  <div class="experience">
<!--    <div class="experience__image">-->
<!--      <img src="https://picsum.photos/200/160?2" alt="">-->
<!--    </div>-->
    <div class="experience__info">
      <h3 class="experience__info__title">Pindex.sk</h3>
      <div class="experience__info__time">MAR 2014 - JUL 2016</div>
      <p class="experience__info__description">
        Pindex.sk is professional network for students and teachers of universities and high schools in Slovakia. Where the students could share their
        notes about teachers and give them a grades based on some objective metrics.
        It was indeed interesting experience to work on that project especially because by that time I was a student my self.
      </p>
      <p>
        I rewrote their platform to a new standards using up to date versions of <strong>Laravel</strong> with performance in mind.
        It was wonderful experience because I had to implement <strong>pixel perfect design</strong> given me by the very skilled designer.
      </p>
    </div>
  </div>
</article>
