<header>
  <h2><span>Education</span></h2>
</header>
<article>
  <div class="experience">
    <div class="experience__image">
      <img src="/assets/but.png" alt="Brno University of Technology Logo">
    </div>
    <div class="experience__info">
      <h3 class="experience__info__title">Brno University of Technology</h3>
      <div class="experience__info__time">2013 - 2020 Master of Managerial Informatics</div>
      <p class="experience__info__description">
       Developing application is not the only thing that developers should focus on. It is also important to <strong>understand client's needs</strong> (especially when freelancing),
        <strong>to understand their perspective</strong> and also other processes that are involved behind every IT projects. That was something that BUT taught me.
      </p>
    </div>
  </div>

  <div class="experience">
    <div class="experience__image">
      <img src="/assets/rit.png" alt="Rochester Institute of Technology Logo">
    </div>
    <div class="experience__info">
      <h3 class="experience__info__title">Rochester Institute of Technology - Croatia</h3>
      <div class="experience__info__time">2019 Erasmus+ Exchange Program</div>
      <p class="experience__info__description">
        As an active member of <strong>ESN Network</strong> I had an opportunity to study at <strong>RIT - Croatia</strong>. I had a chance to experience
        <strong>American</strong> way of education
      </p>
      <p>It was also great opportunity to <strong>improve my English</strong> as we were forbidden to speak any other language at school. Also helping American
        students with a cultural shock helped very much.</p>
    </div>
  </div>
</article>
