<aside class="main-aside"></aside>
<main>
  <div class="wrapper">

    <section class="no-break">
      <header>
        <h2><span>About Me</span></h2>
      </header>
      <article>
        <p>I am an IT professional with over <strong>8 years of experience</strong>. For the last 4 years I almost exclusively <strong>specialize
          at Angular Framework</strong>. Among other technologies I have recently touched belongs <strong>IONIC, Firebase, NestJs</strong> and many others. </p>
        <p>I love clean and well structured code, but the thing I love the most is being part of frontend community.</p>
      </article>
    </section>
    <section id="skills" class="no-break">
      <app-skills></app-skills>
    </section>
    <section id="experience">
      <app-experience></app-experience>
    </section>
    <section id="education">
      <app-education></app-education>
    </section>

  </div>

  <button
    class="download-button"
    (click)="onDownload()"
  >
    <img class="download-button__icon" src="/assets/download.png" alt="Download as PDF">
    <div class="download-button__text">Download as PDF</div>
  </button>
</main>
